import React from 'react';
import { graphql, PageProps } from 'gatsby';
import PointsEcuador from '../../components/Landing/PointsEcuador';
import SEO from '../../components/SEO';

const PAGE_TITLE = 'Puntos de retiro en efectivo Ecuador';
const PAGE_DESCRIPTION =
  'Envía dinero a Ecuador con CurrencyBird y retira en efectivo. Tarifas bajas y muchos puntos de retiro. Consulta nuestro mapa.';

const PointsEcuadorPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <PointsEcuador />
    </>
  );
};

export default PointsEcuadorPage;

export const pageQuery = graphql`
  query PointsEcuadorPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
