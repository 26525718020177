import React from 'react';
import PaymentPoints from '../PaymentPoints/PaymentPoints';

const PointsEcuador = () => {
  return (
    <div className="landing-page">
      <PaymentPoints countryCode="EC" countryName="Ecuador" />
    </div>
  );
};

export default PointsEcuador;
